import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./Contact.scss";

interface ContactDataProps {
    contactData: any;
}

const ContactUsComponent: React.FC<ContactDataProps> = ({ contactData }) => {


    return (
        <>
            <div className="contact-us-section">
                <div className="our-office">
                    <h3>{contactData?.data?.offices?.heading}</h3>
                    <Row>
                        {contactData?.data?.offices?.country?.map((item: any, index: number) => (
                            <Col md={6} key={index} data-aos="fade-up"
                                data-aos-duration="2000">
                                <Card className="our-office-content">
                                    <Row style={{ alignItems: "center" }}>
                                        <Col xs={4}>
                                            <img className="country-image" src={item?.image} alt={item?.country} />
                                        </Col>
                                        <Col xs={6}>
                                            <h4>{item?.country}</h4>
                                            <h4>{item?.country1}</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={1}>
                                            <img src={item?.locationicon} alt="Location" />
                                        </Col>
                                        <Col xs={11}>
                                            <div>
                                                <small>{item?.location}</small>
                                            </div>
                                            <small>{item?.location1}</small>
                                        </Col>
                                    </Row>
                                    <Row style={{ alignItems: "center" }}>
                                        <Col xs={1}>
                                            <img src={item?.phoneicon} alt="Phone" />
                                        </Col>
                                        <Col>
                                            <small>{item?.phone}</small>
                                        </Col>
                                    </Row>
                                    <Row style={{ alignItems: "center" }}>
                                        <Col xs={1}>
                                            <img src={item?.emailicon} alt="Email" style={{width:"17px"}}/>
                                        </Col>
                                        <Col>
                                            <small>{item?.email}</small>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        </>
    );
}

export default ContactUsComponent;
