import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import AOS from "aos";
import "aos/dist/aos.css";

const RenderPageCompo = () => {
    const [openInterior, setOpenInterior] = useState(false);
    const [openExterior, setOpenExterior] = useState(false);
    const [selectedImages, setSelectedImages] = useState<any>([]);
    const [selectedImagesExterior, setSelectedImagesExterior] = useState<any>([]);
    const [startIndexInterior, setStartIndexInterior] = useState(0); // Track the selected interior image index
    const [startIndexExterior, setStartIndexExterior] = useState(0); // Track the selected exterior image index

    // Interior images with thumbnails
    const interiorImages = Array.from({ length: 40 }, (_, i) => ({
        original: `/assets/images/renders/render_images/interior/${i + 1}.jpg`,
        thumbnail: `/assets/images/renders/render_images/interior/thumbnails/${i + 1}.jpg`, // Thumbnail path
    }));

    // Exterior images with thumbnails
    const exteriorImages = Array.from({ length: 11 }, (_, i) => ({
        original: `/assets/images/renders/render_images/exterior/${i + 1}.jpg`,
        thumbnail: `/assets/images/renders/render_images/exterior/thumbnails/${i + 1}.jpg`, // Thumbnail path
    }));

    useEffect(() => {
        setSelectedImages(interiorImages);
        setSelectedImagesExterior(exteriorImages);
    }, []);

    const handleInteriorClick = (index: number) => {
        setStartIndexInterior(index); // Set the clicked image index
        setOpenInterior(true); // Open the Lightbox
    };

    const handleExteriorClick = (index: number) => {
        setStartIndexExterior(index); // Set the clicked image index
        setOpenExterior(true); // Open the Lightbox
    };

    const renderSlider = (images: any, handleClick: any) => (
        <Slider
            {...{
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                autoplay: true,
                autoplaySpeed: 4000
            }}
        >
            {images.slice(0, 3).map((img: any, index: any) => (
                <div key={index} onClick={() => handleClick(index)} style={{ cursor: "pointer" }}>
                    <img className="img-fluid" src={img.thumbnail} alt={`Image ${index + 1}`} />
                    <div className="preview-images">
                        <small>Preview</small>
                    </div>
                </div>
            ))}
        </Slider>
    );

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div className="render-page tabsHeading">
            <h6>
                <span>Renders</span>
            </h6>
            <div className="pre-design-content">
                <Row className="Interior-renders first-slide-service">
                    <Col md={6} className="service-content-new order-md-1 order-2" data-aos="fade-right">
                        <div>
                            <strong className="main-page-heading" style={{ textAlign: "center" }}>
                                <span>Interior Renders</span>
                            </strong>
                            <div className="renders-content">
                                <p>
                                    <strong>Interior Renders</strong> provide photorealistic, detailed views of interior
                                    spaces, helping you visualize designs with precision. Ideal for real estate,
                                    interior design, and architectural projects, these renders showcase layouts and decor
                                    beautifully.
                                </p>
                                <ul>
                                    <li>Photorealistic, high-quality renderings of interior spaces.</li>
                                    <li>Highlights furniture, lighting, textures, and materials.</li>
                                    <li>Ideal for real estate, interior design, and architectural visualization.</li>
                                    <li>Enhances client presentations with accurate and stunning visuals.</li>
                                    <li>Easily integrable into websites and marketing materials.</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col md={7} className="order-md-2 order-1">
                        <div style={{ cursor: "pointer", width: "100%", margin: "auto", position: "relative" }}>
                            {renderSlider(selectedImages, handleInteriorClick)}
                            <Lightbox
                                open={openInterior}
                                close={() => setOpenInterior(false)}
                                slides={interiorImages.map(img => ({
                                    src: img.original,
                                    thumbnail: img.thumbnail, // Add thumbnails to Lightbox
                                }))}
                                index={startIndexInterior} // Pass the clicked index to Lightbox
                            />
                             <div className="preview-images bottom-menu">
                                <Link to="/portfolio?tab=renders"><img src="/assets/images/see_all.png" /></Link>
                                <img onClick={() => { handleInteriorClick(startIndexInterior) }} src="/assets/images/full.png" width={"50px"} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="second-slide-service">
                    <Col md={7}>
                        <div style={{ cursor: "pointer", width: "100%", margin: "auto", position: "relative" }}>
                            {renderSlider(selectedImagesExterior, handleExteriorClick)}
                            <Lightbox
                                open={openExterior}
                                close={() => setOpenExterior(false)}
                                slides={exteriorImages.map(img => ({
                                    src: img.original,
                                    thumbnail: img.thumbnail, // Add thumbnails to Lightbox
                                }))}
                                index={startIndexExterior} // Pass the clicked index to Lightbox
                            />
                            <div className="preview-images top-menu" >
                                <Link to="/portfolio?tab=renders"><img src="/assets/images/see_all.png" /></Link>
                                <img onClick={() => { handleExteriorClick(startIndexExterior) }} src="/assets/images/full.png" width={"50px"} />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="service-content-new1" data-aos="fade-left">
                        <strong className="main-page-heading" style={{ textAlign: "center" }}>
                            <span>Exterior Renders</span>
                        </strong>
                        <div className="renders-content">
                            <p>
                                <strong>Exterior Renders</strong> offer photorealistic, high-quality visuals of building
                                exteriors, showcasing architectural details and landscapes. Perfect for real estate and
                                architectural projects, these renders highlight every aspect of outdoor design.
                            </p>
                            <ul>
                                <li>Photorealistic exterior views of buildings and landscapes.</li>
                                <li>Showcases lighting, textures, and materials with precision.</li>
                                <li>Ideal for real estate, architecture, and outdoor design.</li>
                                <li>Enhances presentations with stunning, detailed visuals.</li>
                                <li>Easily integrated into websites and marketing materials.</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default RenderPageCompo;
