import React from "react";
import { Col, Row } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import "./style.scss";

const GreenBuildingComp = () => {
    return (
        <>
            <div className="green-section tabsHeading">
                <h6><span>Green Building</span></h6>
                <div className="first-carousel green-carousel">
                    <Carousel data-bs-theme="dark">
                        <Carousel.Item interval={4000}>
                            <Row className="first-slide-service" style={{padding:'0'}}>
                                <Col md={5} xs={{ order: 2 }} className="mt-md-0 mt-2" data-aos="fade-right">
                                    <div className="pre-design-content">
                                        <h3>Unlock 45-70% Gains by Pre-Design, Design, and Materials</h3>
                                        <ul>
                                            <li>Pre-Construction: 45% – 70% efficiency gains</li>
                                            <li>During Construction: 10% – 20% efficiency gains</li>
                                            <li>Post-Construction: 10% – 15% efficiency gains</li>
                                        </ul>
                                        <p>Efficiency gains can be substantial when optimizing the pre-design, design, and material selection phases. Developers can achieve 45-70% efficiency gains in pre-construction, 10-20% during construction, and 10-15% post-construction.</p>
                                    </div>
                                </Col>
                                <Col md={7} className="order-md-2 order-1">
                                    <img src="/assets/images/service/03_assets.jpg" className="img-fluid" />
                                </Col>
                            </Row>
                            <Row className="second-slide-service">
                                <Col md={7} >
                                    <img src="/assets/images/service/04_assets.jpg" className="img-fluid" />
                                </Col>
                                <Col md={5} className="order-md-2 order-1 mt-md-0 mt-4" data-aos="fade-left">
                                    <div className="pre-design-content">
                                        <h3>A Smart Investment: Benefits for Developers</h3>
                                        <ul>
                                            <li>20% lower maintenance costs</li>
                                            <li>10-20% higher property values</li>
                                            <li>34% increased rental rates for certified properties</li>
                                        </ul>
                                        <p>Key steps for architects, developers, and occupants include obtaining Green Building and ESG Certifications, which ensure compliance with the highest sustainability standards and enhance the reputation and competitiveness of property owners. Prioritizing key ESG metrics is essential for successful green building projects. The benefits for developers are compelling: they can expect 20% lower maintenance costs, 10-20% higher property values, and a 34% increase in rental rates for certified properties. Ultimately, these practices lead to enhanced profitability and marketability in the competitive real estate market.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item interval={4000}>
                            <Row style={{ justifyContent: 'end', alignItems: 'center', position: 'relative' }}>
                                <Col xs={{ order: 2 }} md={{ span: 5, order: 1 }} className="" data-aos="fade-right">
                                    <div className="pre-design-content">
                                        <h3>Unlock the Value of Green Buildings</h3>
                                        <ul>
                                            <li>Green buildings can increase property values by an average of 7%.</li>
                                            <li>Green buildings can improve indoor air quality by as much as 50%.</li>
                                        </ul>
                                        <p>Sustainable building practices are essential in today's construction industry, where up to 40% of global energy is consumed for constructing, operating, and maintaining buildings. This sector also contributes to one-third of global CO2 emissions. By adopting sustainable methods, energy usage in buildings can be reduced by up to 80%, making a significant impact on our environment.</p>
                                    </div>
                                </Col>
                                <Col xs={{ order: 1 }} md={{ span: 7, order: 2 }}>
                                    <img src="/assets/images/service/01_assets.jpg" className="img-fluid" />
                                </Col>
                            </Row>
                            <Row style={{ justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
                                <Col md={7}>
                                    <img src="/assets/images/service/02_assets.jpg" className="img-fluid" />
                                </Col>
                                <Col md={5} className="mt-md-0 mt-4" data-aos="fade-left">
                                    <div className="pre-design-content">
                                        <h3>The Economic Upside of Green Buildings</h3>
                                        <ul>
                                            <li>The global green building market is projected to reach $1 trillion by 2033.</li>
                                        </ul>
                                        <p>Green buildings offer substantial economic benefits, including an average increase in property values of 7%. This increase can be attributed to various factors such as energy efficiency, enhanced aesthetics, and improved occupant satisfaction. The global green building market is projected to reach $1 trillion by 2033, highlighting the growing demand for sustainable construction solutions. </p>
                                    </div>
                                </Col>
                            </Row>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
        </>
    )
}
export default GreenBuildingComp;