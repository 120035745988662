import React, { useRef, useState } from "react";
import ReactPlayer from 'react-player';
import { Modal, Button, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Slider from "react-slick";

const Videos360 = () => {
    const [mediaShow, setMediaShow] = useState<any>(true);
    const [selectedMedia, setSelectedMedia] = useState<any>(null);
    const [open, setOpen] = useState(false);
    const playerRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 4000
    };

    const interiorMedia = [
        {
            title: "seef",
            type: "video",
            src: "https://www.youtube.com/watch?v=5ZKGONwheuI=PLbzBcKDpIG9hj4YnWyAeo-iivTIbSEst7&index=3",
            thumbnail: "/assets/images/renders/videos_360/seef2.jpg",
        },
        {
            title: "seef1",
            type: "video",
            src: "https://www.youtube.com/watch?v=3YJ9oyDiOYA=PLbzBcKDpIG9hj4YnWyAeo-iivTIbSEst7&index=4",
            thumbnail: "/assets/images/renders/videos_360/seef1.jpg",
        },
        {
            title: "al_ramz",
            type: "video",
            src: "https://www.youtube.com/watch?v=IhhYZawvOkA=PLbzBcKDpIG9hj4YnWyAeo-iivTIbSEst7&index=5",
            thumbnail: "/assets/images/renders/videos_360/al_ramz1.jpg",
        },
        {
            title: "Green Plaza",
            type: "video",
            src: "https://www.youtube.com/watch?v=pLupt-XwyRA&index=1",
            thumbnail: "/assets/images/renders/videos_360/Green_plaza.jpg",
        },
        {
            title: "Green City",
            type: "video",
            src: "https://www.youtube.com/watch?v=8sOXQY-faPk&index=2",
            thumbnail: "/assets/images/renders/videos_360/Green_City.jpg",
        },
        // Add more media objects as needed
    ];

    const handleCloseModal = () => {
        setOpen(false);
        setSelectedMedia(null);
        setMediaShow(true);
    };

    const handleThumbnailClick = (media: any) => {
        setSelectedMedia(media);
        setOpen(true);
    };

    return (
        <>
            <div className="renderImages tabsHeading">
                <h6><span>360 Videos</span></h6>
                <div className="renderImages360 render-page pre-design-content">
                    {mediaShow && (
                        <div className="row first-slide-service">
                            <Col md={6} className="service-content-new order-md-1 order-2" data-aos="fade-right">
                                <strong className="main-page-heading" style={{ textAlign: "center" }}>
                                    <span>Interior 360 Videos</span>
                                </strong>
                                <div className="renders-content render-360">
                                    <p><strong>Interior 360 Videos </strong>are a rare and game-changing offering, providing an immersive, cinematic walkthrough of interior spaces. Perfect for real estate sales and marketing, these videos captivate with unmatched realism and motion. What sets them apart is the ability for viewers to pause and explore every angle in 360 degrees, creating a truly interactive experience.</p>
                                    <ul>
                                        <li>Exclusive 360-degree tours that stand out in the market.</li>
                                        <li>Highlights layouts, decor, and lighting in stunning detail.</li>
                                        <li>Tailored for high-impact presentations to drive sales.</li>
                                        <li>Seamlessly shareable on websites, social media, and campaigns.</li>
                                    </ul>
                                    {/* <Link to="/portfolio?tab=360renders"><button >See More</button></Link> */}
                                </div>
                            </Col>
                            <Col md={7} style={{ cursor: 'pointer' }}>
                                <Slider {...settings}>
                                    {
                                        interiorMedia.slice(0, 3).map((media, index) => (
                                            <div key={index} className="col-3" style={{ cursor: "pointer", marginBottom: "20px" }}>
                                                <div className="ImagesBox360" onClick={() => handleThumbnailClick(media)}
                                                    style={{ position: "relative" }}>
                                                    <img
                                                        className="img-fluid"
                                                        src={media.thumbnail}
                                                        alt="Thumbnail"
                                                    />
                                                    <div className="preview-images bottom-menu">
                                                        <Link to="/portfolio?tab=videos"><img src="/assets/images/see_all.png" /></Link>
                                                        <img onClick={() => handleThumbnailClick(media)} src="/assets/images/full.png" width={"50px"} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </Col>
                        </div>
                    )}
                </div>
            </div>

            <Modal show={open} onHide={handleCloseModal} className="render-modal-section" style={{ cursor: 'pointer' }}>
                <Modal.Body>
                    {selectedMedia && selectedMedia.type === 'video' && (
                        <ReactPlayer
                            url={selectedMedia.src}
                            className='react-player'
                            playing={true}
                            autoPlay={true}
                            width='100%'
                            height='100%'
                            controls={true}
                            loop={true}
                            ref={playerRef}
                            playsinline={true}
                            muted
                        />
                    )}
                    <button onClick={handleCloseModal} className="render-closeBtn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                        </svg>
                    </button>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default Videos360