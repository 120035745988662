import * as React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useApp } from '../../../hooks/AppHooks';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

const AssurancePage: React.FC = () => {
    const { homeEnglishData } = useApp();

    const data = homeEnglishData?.home;
    const texts = [
        "Discover Our Most In-Demand Solutions",
        "Explore Our Cutting-Edge Technologies",
        "Innovating Real Estate Tech"
    ];

    const [currentIndex, setCurrentIndex] = React.useState<number>(0);
    const [animate, setAnimate] = React.useState<boolean>(false);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
            setAnimate(true); // Trigger animation
        }, 5000); // Change text every 3 seconds

        return () => clearInterval(interval);
    }, []);

    const settings1 = {
        dots: false,
        infinite: false,
        arrows: false,
        autoplay: false,
        loop: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        mute: false,
        muted: false,
    };

    const isVideo = (filename: string) => {
        const videoExtensions = ['.mp4', '.mov', '.avi', '.wmv'];
        return videoExtensions.some(ext => filename.endsWith(ext));
    };

    return (
        <div className="assurance-section">
            <Container>
                <div className="ourProcess featured-service">
                    <div className="ourProcessSlide">
                        <h3 className="text-center">Featured Services</h3>
                        <div className="animated-text-container">
                            <p className={`text-center animate-text ${animate ? 'animate-background' : ''}`}>
                                <span> {texts[currentIndex]}</span>
                            </p>
                        </div>
                        <Slider {...settings1} className='desktop-view-caraousel'>
                            {data?.ourProcess?.ourtTrust?.slideImage.map((item: any, index: number) => (
                                <div key={index}>
                                    <Row key={index}>
                                        <Col md={11} xs={12}>
                                            <Card className="Feature-service">
                                                <Card.Body>
                                                    {/* <h5>{item?.title}</h5> */}
                                                    <video
                                                        playsInline
                                                        autoPlay
                                                        muted
                                                        loop
                                                        preload="auto"
                                                        // crossOrigin="anonymous"
                                                        className={`cara-height${index}`}
                                                    >
                                                        <source src={item?.imageThumbnail} type="video/mp4" />
                                                    </video>
                                                </Card.Body>
                                                <div className='service-gallery'>
                                                    <h5>{item?.title}</h5>
                                                    <div className='feature-box'>
                                                        <Link style={{ textAlign: "center" }} to={item?.title === "Realty Verse" ? "RealtyVerse" : "renders"}><button>View Gallery</button></Link>
                                                        <Link style={{ textAlign: "center" }} to="contact-us"><button>Get in touch with us</button></Link>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </Slider>
                        <Carousel className='mobile-view-caraousel'>
                            {data?.ourProcess?.ourtTrust?.slideImage.map((item: any, index: number) => (
                                <Carousel.Item interval={99999}>
                                    <div key={index}>
                                        <Row key={index}>
                                            <Col md={11} xs={12}>
                                                <Card className="Feature-service">
                                                    <Card.Body>
                                                        {/* <h5>{item?.title}</h5> */}
                                                        <video
                                                            playsInline
                                                            autoPlay
                                                            muted
                                                            loop
                                                            preload="auto"
                                                            // controls
                                                            // preload="auto"
                                                            crossOrigin="anonymous"
                                                            className={`cara-height${index}`}
                                                            src={item?.imageThumbnail}
                                                            style={{
                                                                borderRadius: "20px",
                                                                width: "100%",
                                                                height: "100%",
                                                                objectFit: 'cover'
                                                            }}
                                                        >
                                                            {/* <source  type="video/mp4" /> */}
                                                        </video>
                                                    </Card.Body>
                                                    <div className='service-gallery'>
                                                        <h5>{item?.title}</h5>
                                                        <div className='feature-box'>
                                                            <Link style={{ textAlign: "center" }} to={item?.title === "Realty Verse" ? "RealtyVerse" : "renders"}><button>View Gallery</button></Link>
                                                            <Link style={{ textAlign: "center" }} to="contact-us"><button>Get in touch with us</button></Link>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </Container>
            <Container>
                <div className='our-assurance-box'>
                    <Row>
                        <Col xs={12} sm={6} lg={3}>
                            <Card className="years-complete">
                                <Card.Body style={{ lineHeight: '0.8' }}>{data?.ourAssurance?.years}
                                    <h3>Decades</h3>
                                </Card.Body>

                            </Card>
                        </Col>
                        <Col xs={12} sm={6} lg={3}>
                            <Card className="years-content">
                                <Card.Body>
                                    <h3 style={{ fontSize: "25.5px", textTransform: "capitalize" }}>{data?.ourAssurance?.yearsContent}</h3>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} lg={6}>
                            <Card style={{ textAlign: 'left' }}>
                                <Card.Body>
                                    <h3>{data?.ourAssurance?.title}</h3>
                                    <p style={{ textAlign: 'justify' }}>{data?.ourAssurance?.content}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Row className="accelerate-box">
                    <h3 style={{ textAlign: 'center', fontSize: "30px", marginBottom: "30px", textTransform: "capitalize" }}>Here's how you can achieve faster sales cycles:</h3>
                    {data?.ourAssuranceContent.map((item: any, index: number) => (
                        <Col key={index} xs={12} sm={6} lg={3}>
                            <div className='flip-card'>
                                <Card className="flip-card-inner" >
                                    <img src={item?.image} className='img-fluid' />
                                    <Card.Body className="text-center flip-card-front">
                                        <div style={{ marginBottom: "10px" }}>
                                            <h5 style={{ margin: "0" }}>{item?.title}</h5>
                                            <h5>{item?.subtitle}</h5>
                                        </div>
                                    </Card.Body>
                                    <div className='flip-card-back'>
                                        <h4 style={{ color: "#f2f5f7", padding: "0  20px" }}>{item?.detail}</h4>
                                    </div>
                                </Card>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>

        </div>
    );
};

export default AssurancePage;