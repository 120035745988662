import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";

// Define the type for media items
interface MediaItem {
    src: string;
    thumbnail: string;
}

// Define the type for mediaData
interface MediaData {
    interior: MediaItem[];
    exterior: MediaItem[];
}

// Define the type for openModal state
interface OpenModalState {
    open: boolean;
    type: keyof MediaData; // 'interior' | 'exterior'
    index: number;
}

const CinematicVideos = () => {
    // Use strict typing for openModal state
    const [openModal, setOpenModal] = useState<OpenModalState>({ open: false, type: 'interior', index: 0 });
    const handleClose = () => setOpenModal({ open: false, type: 'interior', index: 0 });

    // Explicitly type the mediaData object
    const mediaData: MediaData = {
        interior: [
            { src: "https://youtu.be/YuP3GcK2NJc", thumbnail: "/assets/images/renders/cinematic/interior/green_city.jpg" },
            { src: "https://youtu.be/C20DKpNCvSM", thumbnail: "/assets/images/renders/cinematic/interior/green_plaza.jpg" },
            { src: "https://youtu.be/_asIFC8uDOg", thumbnail: "/assets/images/renders/cinematic/exterior/alramz.jpg" },
        ],
        exterior: [
            { src: "https://youtu.be/IsXeMxlbXA8", thumbnail: "/assets/images/renders/cinematic/exterior/green_city.jpg" },

            { src: "https://youtu.be/y9cP6q3_Ke4", thumbnail: "/assets/images/renders/cinematic/exterior/Seef.jpg" },
            { src: "https://youtu.be/MtoRwShZc5Y", thumbnail: "/assets/images/renders/cinematic/exterior/alramz_2.jpg" },
        ]
    };

    const settings = {
        dots: true, infinite: true, slidesToShow: 1, slidesToScroll: 1, mute: false,
        muted: false,autoplay: true , speed: 500, autoplaySpeed: 4000
    };

    const renderSection = (type: keyof MediaData, title: string, description: string, items: Array<MediaItem>, reverse: boolean) => (
        <>
            {reverse ? (
                <>
                    <Row className="second-slide-service">
                        <Col md={7} >
                            <Slider {...settings}>
                                {items.map((item, index) => (
                                    <div key={index} className="cinematic-videos-images" style={{ cursor: "pointer", width: '100%', margin: 'auto', position: 'relative' }} onClick={() => setOpenModal({ open: true, type, index })}>
                                        <img src={item.thumbnail} alt={`${type} thumbnail`} className="img-fluid" />
                                        <div className="preview-images top-menu">
                                            <Link to="/portfolio?tab=cinematic"><img src="/assets/images/see_all.png" /></Link>
                                            <img onClick={() => setOpenModal({ open: true, type, index })} src="/assets/images/full.png" width={"50px"} />
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </Col>
                        <Col md={6} className="service-content-new1" data-aos="fade-left">
                            <strong className="main-page-heading"><span>{title}</span></strong>
                            <div className="renders-content">
                                <p><strong>Exterior Cinematic Videos</strong> {description}</p>
                                <ul>
                                    {type === 'interior' ? (
                                        <>
                                            <li>Fully immersive 360-degree exterior views.</li>
                                            <li>Realistic rendering with high-resolution details.</li>
                                            <li>Enhances client engagement and interaction.</li>
                                            <li>Perfect for real estate, landscaping, and architectural design.</li>
                                            <li>Easy integration into websites for seamless viewing on any device.</li>
                                        </>
                                    ) : (
                                        <>
                                            <li>High-quality, immersive cinematic tours of interiors.</li>
                                            <li>Showcase lighting, textures, and design elements dynamically.</li>
                                            <li>Engages clients with realistic, visually compelling content.</li>
                                            <li>Perfect for real estate, interior design, and architecture projects.</li>
                                            <li>Easily shareable and integrable into websites or presentations.</li>
                                        </>
                                    )}
                                </ul>
                                {/* <Link to="/portfolio?tab=cinematic"><button >See More</button></Link> */}
                            </div>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Row className="first-slide-service">
                        <Col md={6} className="interior-tab service-content-new order-md-1 order-2" data-aos="fade-right">
                            <strong className="main-page-heading"><span>{title}</span></strong>
                            <div className="renders-content interior-cinematic">
                                <p><strong>Interior Cinematic Videos</strong> {description}</p>
                                <ul>
                                    {type === 'interior' ? (
                                        <>
                                            <li>High-quality, immersive cinematic tours of interiors.</li>
                                            <li>Showcase lighting, textures, and design elements dynamically.</li>
                                            <li>Engages clients with realistic, visually compelling content.</li>
                                            <li>Perfect for real estate, interior design, and architecture projects.</li>
                                            <li>Easily shareable and integrable into websites or presentations.</li>
                                        </>
                                    ) : (
                                        <>
                                            <li>Fully immersive 360-degree exterior views.</li>
                                            <li>Realistic rendering with high-resolution details.</li>
                                            <li>Enhances client engagement and interaction.</li>
                                            <li>Perfect for real estate, landscaping, and architectural design.</li>
                                            <li>Easy integration into websites for seamless viewing on any device.</li>
                                        </>
                                    )}
                                </ul>
                                {/* <Link to="/portfolio?tab=cinematic"><button >See More</button></Link> */}
                            </div>
                        </Col>
                        <Col md={7}>
                            <Slider {...settings}>
                                {items.map((item, index) => (
                                    <div key={index} className="cinematic-videos-images" style={{ cursor: "pointer", width: '100%', margin: 'auto', position: 'relative' }} onClick={() => setOpenModal({ open: true, type, index })}>
                                        <img src={item.thumbnail} alt={`${type} thumbnail`} className="img-fluid" />
                                        <div className="preview-images bottom-menu">
                                            <Link to="/portfolio?tab=cinematic"><img src="/assets/images/see_all.png" /></Link>
                                            <img onClick={() => setOpenModal({ open: true, type, index })} src="/assets/images/full.png" width={"50px"} />
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );

    return (
        <div className="render-page tabsHeading">
            <h6><span>Cinematic Videos</span></h6>
            <div className="pre-design-content">
                {renderSection('interior', 'Interior Cinematic Videos', 'Experience high-quality, immersive tours...', mediaData.interior, false)}
                {renderSection('exterior', 'Exterior Cinematic Videos', 'Fully immersive 360-degree exterior views...', mediaData.exterior, true)}

                {/* Modal for video player */}
                <Modal show={openModal.open} onHide={handleClose} centered className="render-modal-section">
                    <ReactPlayer width="100%" height="100%" url={mediaData[openModal.type][openModal.index]?.src} controls playing muted />
                    <button onClick={handleClose} className="render-closeBtn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                        </svg>
                    </button>
                </Modal>
            </div>
        </div>
    );
};

export default CinematicVideos;
