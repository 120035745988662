import React, { useEffect, useRef, useState, useCallback } from "react";
import Slider from "react-slick";
import 'animate.css';
import { useApp } from "../../../hooks/AppHooks";
import { Container } from "react-bootstrap";
import throttle from "lodash.throttle";

interface VideoUrlLink {
    videourl: any;
}

const VideoComponent: React.FC<VideoUrlLink> = ({ videourl }) => {
    const [duration, setDuration] = useState<number | null>(null);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [sliderIndex, setSliderIndex] = useState<number>(0);
    const sliderRef = useRef<Slider>(null);
    const videoRefs = useRef<HTMLVideoElement[]>([]);
    const { homeEnglishData } = useApp();
    const [currentTime, setCurrentTime] = useState<number>(0);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        mute: false,
        muted: false,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (index: number) => {
            handleSlideChange(index);
        },
    };

    const togglePlay = () => {
        const currentVideo = videoRefs.current[sliderIndex];
        if (isPlaying) {
            currentVideo?.pause();
            setIsPlaying(false);
        } else {
            currentVideo?.play();
            setIsPlaying(true);
        }
    };

    const throttledTimeUpdate = useCallback(
        throttle((currentTime: number) => {
            setCurrentTime(Math.floor(currentTime)); // Ignore decimal part
        }, 1000),
        []
    );

    const handleTimeUpdate = (event: any) => {
        const currentTime = event.target.currentTime;
        throttledTimeUpdate(currentTime);
    };

    const handleSlideChange = (index: number) => {
        const currentVideo = videoRefs.current[sliderIndex];
        if (currentVideo) {
            currentVideo.pause();
            currentVideo.currentTime = 0;
            setIsPlaying(false);
        }

        setSliderIndex(index);
        setDuration(null);
        setCurrentTime(0);
        setIsPlaying(false);

        const newVideo = videoRefs.current[index];
        if (newVideo) {
            newVideo.currentTime = 0;
            newVideo.play().catch((error) => console.log(error));
            setIsPlaying(true);
            setDuration(Math.floor(newVideo.duration));

            newVideo.removeEventListener("ended", handleVideoEnded);
            newVideo.addEventListener("ended", handleVideoEnded);
        }
    };

    const handleListItemClick = (index: number) => {
        const currentVideo = videoRefs.current[sliderIndex];
        if (currentVideo) {
            currentVideo.pause();
            currentVideo.currentTime = 0;
            setIsPlaying(false);
        }

        if (sliderRef.current) {
            sliderRef.current.slickGoTo(index);
        }
    };

    const handleLoadedData = (event: any, index: number) => {
        const duration = event.target.duration;
        if (index === sliderIndex) {
            setDuration(Math.floor(duration));

            if (sliderRef.current) {
                sliderRef.current.slickGoTo(index);
            }
        }
    };

    const handleVideoEnded = () => {
        const currentVideo = videoRefs.current[sliderIndex];
        if (currentVideo) {
            currentVideo.pause();
            currentVideo.currentTime = 0;
            setIsPlaying(false);
        }

        if (sliderRef.current) {
            const nextIndex = (sliderIndex + 1) % (videourl?.videosection?.length || 1);
            sliderRef.current.slickGoTo(nextIndex);
        }
    };

    useEffect(() => {
        videoRefs.current = Array(videourl?.videosection?.length || 0)
            .fill(0)
            .map((_, i) => videoRefs.current[i] || null);
    }, [videourl?.videosection?.length]);

    useEffect(() => {
        const currentVideo = videoRefs.current[sliderIndex];
        if (currentVideo) {
            currentVideo.addEventListener("ended", handleVideoEnded);
            return () => {
                currentVideo.removeEventListener("ended", handleVideoEnded);
            };
        }
    }, [sliderIndex]);

    const radius = 50; // Radius of the circle
    const circumference = 2 * Math.PI * radius; // Circumference of the circle

    const progress = (currentTime / (duration || 1)) * circumference;

    return (
        <div className="video-section">
            <Slider {...settings} initialSlide={sliderIndex} ref={sliderRef}>
                {videourl?.videosection?.map((videoUrl: any, index: number) => (
                    <div key={index}>
                        <video
                            ref={(el) => (videoRefs.current[index] = el as HTMLVideoElement)}
                            src={videoUrl.videoLink}
                            onLoadedData={(event) => handleLoadedData(event, index)}
                            playsInline
                            autoPlay
                            muted
                            preload="auto"
                            onTimeUpdate={handleTimeUpdate}
                            width={800}
                            height={800}
                        />
                    </div>
                ))}
            </Slider>
            <div className="video-title-box">
                <Container>
                    <div className="video-heading"></div>
                    <ul className="video-content">
                        {videourl?.videosection?.map((videoUrl: any, index: number) => (
                            <li
                                key={index}
                                className={sliderIndex === index ? "active" : ""}
                                onClick={() => handleListItemClick(index)}
                            >
                                <p>{videoUrl?.firstNumber}</p>
                                <p>{videoUrl?.subHeading1}</p>
                            </li>
                        ))}
                    </ul>
                    <div className="progress-box">
                        <div className="progress-bar-circle">
                            <svg width="80" height="80" viewBox="0 0 120 120">
                                <circle
                                    cx="60"
                                    cy="60"
                                    r={radius}
                                    stroke="#ffffff"
                                    strokeWidth="3"
                                    fill="none"
                                />
                                <circle
                                    cx="60"
                                    cy="60"
                                    r={radius}
                                    stroke="#578BC8"
                                    strokeWidth="6"
                                    fill="none"
                                    strokeDasharray={circumference}
                                    strokeDashoffset={circumference - progress}
                                    strokeLinecap="round"
                                />
                            </svg>
                            <small
                                style={{
                                    position: "absolute",
                                    color: 'rgb(242, 245, 247)',
                                    top: 0,
                                    fontSize: '20px',
                                    fontWeight: '500',
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >{`${Math.floor(currentTime / 60)}:${Math.floor(currentTime % 60).toString().padStart(2, '0')}`}</small>
                            <small
                                style={{
                                    position: 'absolute',
                                    left: '0px',
                                    color: 'rgb(242, 245, 247)',
                                    top: '-18px',
                                    fontSize: '15px',
                                    right: 0,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    fontWeight: '600',
                                }}
                            >{duration !== null ? `${Math.floor(duration / 60)}:${Math.floor(duration % 60).toString().padStart(2, '0')}` : "0:00"}</small>
                        </div>
                    </div>
                </Container>
            </div >
            <Container>
                <div className="playBtnBox">
                    <button className="tooglePlayBtn" onClick={togglePlay}>
                        {isPlaying ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#1E2430" className="bi bi-pause-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0z" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#1E2430" className="bi bi-play-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path d="M5.5 6.5L9 8 5.5 9.5z" />
                            </svg>
                        )}
                    </button>
                </div>
            </Container>
        </div >
    );
};

export default VideoComponent;
