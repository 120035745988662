import React, { useEffect } from "react";
import BannerCarousel from "../bannerCaraousel/component";
import ImageSection from "./imagesection/component";
import "./Project.scss"
import { Container } from "react-bootstrap";
import { useApp } from "../../hooks/AppHooks";

const ProjectComponent: React.FC = () => {
    const { homeEnglishData } = useApp();
    const bannerImage = homeEnglishData?.projects?.bannerSection;
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])
    return (
        <>
            <BannerCarousel bannerImage={bannerImage} />
            <Container>
                <div className="project-heading-section">
                    <h3 className="main-page-heading" style={{ textAlign: "center" }}><span>Projects</span></h3>
                    <h3>A Glimpse into Extraordinary Projects</h3>
                    <p>Explore our portfolio, where collaboration with visionary developers and architects brings ideas to life. Each project reflects a seamless partnership, transforming unique visions into stunning 3D visualizations. These aren't just renderings—they're success stories that showcase innovation, precision, and the power of collaboration. Let’s push boundaries and craft visuals that elevate your projects to extraordinary heights.</p>
                </div>
                <ImageSection />
            </Container>
        </>
    )
}
export default ProjectComponent;