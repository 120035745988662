import React, { useState, useRef, useEffect } from "react";
import "./style.scss";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import VideoComponent from "../home/videoSection/component";
import AOS from "aos";
import "aos/dist/aos.css";

interface CuversePropsData {
    videourl: any;
}

const OurOffering: React.FC<CuversePropsData> = ({ videourl }) => {

    const floorplanVideoRef = useRef<HTMLVideoElement>(null);
    const exteriorVideoRef = useRef<HTMLVideoElement>(null);
    const video360Ref = useRef<HTMLVideoElement>(null);
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };


    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div className="our-offering-section">
            <div className="home-page">
                <VideoComponent videourl={videourl} />
                <Container>
                    <div className="transform" style={{ marginTop: "50px" }}>
                        <Row style={{ alignItems: 'center', overflow: 'hidden' }}>
                            <Col md={4} style={{ textAlign: "center" }} data-aos="fade-up">
                                <h3>What's</h3>
                                <h3>"RealtyVerse ?"</h3>
                                <div className="small-text-cuverse">
                                    <small>The ultimate tool for developers </small>
                                    <small style={{ display: "block" }}>who demand the best.</small>
                                </div>
                            </Col>
                            <Col md={8} data-aos="fade-down">
                                <p>
                                    RealtyVerse is a cutting-edge solution designed to revolutionize real estate marketing. It offers detailed floor plans, stunning renders, cinematic videos, amenities information, and interactive panatours—all accessible on any web browser, all at one place. Beyond visualization, RealtyVerse empowers agents with live inventory checks and advanced apartment filtering tools. With just one click, deliver a seamless and engaging experience, ensuring your projects captivate audiences worldwide. Elevate your real estate marketing with RealtyVerse—a powerful, all-in-one platform.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <Container>
                <div className="our-offering-content">
                    <div className="our-offering-floorplan">
                        <h3 style={{ textAlign: "center", marginBottom: '20px', width: "100%", margin: "auto" }}>Introducing Indepth Features of RealtyVerse</h3>
                        <small style={{ textAlign: "center", marginBottom: '20px', width: "100%", margin: "auto", display: 'block' }}>The Next Level in Architectural Visualization.</small>
                        <Row style={{ alignItems: 'center', margin: '40px 0px 10px  0', overflow: 'hidden' }}>
                            <Col md={4} className="what-is-floor" style={{ textAlign: "center" }}
                                data-aos="fade-right">
                                <h3>What is a Floor</h3>
                                <h3>Plan design?</h3>
                            </Col>
                            <Col md={8} data-aos="fade-left">
                                <p>2D architectural drawing is perfect for (different spheres, such as) commercial spaces, office and house layouts, home design, and renovations. In the field of real estate marketing, it’s a great way for posting advertising and brochures of the property to customers without having to show them a sketch. Whether you're starting construction of a new house or home remodeling project, a 2D plan with dimensions is essential to create and manage your idea from start to finish.</p>
                            </Col>
                        </Row>
                        <video
                            ref={floorplanVideoRef}
                            autoPlay
                            loop
                            playsInline
                            preload="auto"
                            muted
                            style={{ borderRadius: "20px", objectFit: "cover" }} width="100%" height="500">
                            <source src="/assets/videos/floorplan.mp4" type="video/mp4" />
                            Your browser does not support HTML video.
                        </video>
                    </div>
                    <div className="transform">
                        <Row style={{ alignItems: 'center' }}>
                            <Col md={6} data-aos="fade-up"
                                style={{ overflow: 'hidden' }}
                                data-aos-duration="1000">
                                <div className={`transform-content ${isExpanded ? "expanded" : "collapsed"}`}>
                                    {/* <div className="transform-content"> */}
                                    <h3 className="mb-md-3 mb-1 ">The Power of Still Renders</h3>
                                    <p>Static plans can't capture your vision like our hyper-realistic still renders. These visuals showcase architectural details, finishes, and aesthetics with clarity, instantly engaging investors and securing approvals. Streamline communication and accelerate sales with renders that bring your designs to life.</p>
                                </div>
                                <button onClick={toggleExpand} style={{ marginTop: "10px" }} className="see-more-btn">
                                    {isExpanded ? "See Less" : "See More"}
                                </button>
                            </Col>
                            <Col md={6} data-aos="fade-down"
                                data-aos-easing="linear"
                                data-aos-duration="1500">
                                <div className="transform-image">
                                    <Carousel data-bs-theme="dark" pause={false}>
                                        <Carousel.Item interval={1000}>
                                            <img slot="first" src="/assets/images/comparison/Exterior.png" alt="Before" className="img-fluid" />
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img slot="second" src="/assets/images/comparison/Interior.png" alt="After" className="img-fluid" />
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="interior-360" style={{ marginTop: "40px" }}>
                        <Row style={{ alignItems: 'center', marginTop: "30px", overflow: 'hidden' }}>
                            <Col md={6} data-aos="fade-right" className="order-md-1 order-2 mt-sm-0 mt-3">
                                <video ref={exteriorVideoRef} preload="auto" autoPlay playsInline loop style={{ borderRadius: "20px" }} muted>
                                    <source src="/assets/images/cuverse/360.mp4" type="video/mp4" />
                                </video>
                            </Col>
                            <Col md={6} data-aos="fade-left" className="order-md-2 order-1">
                                <div className={`transform-content ${isExpanded ? "expanded" : "collapsed1"}`}>
                                    <h3 style={{ margin: "0 0 10px 0 " }} className="mt-md-0 mt-2">The Ultimate Walkthrough: Interactive 360° Videos</h3>
                                    <p style={{ margin: "0 0 20px 0 ", textAlign: "justify" }}>
                                        RealtyVerse brings you a one-of-a-kind offering: immersive 360° interior videos, unmatched in the market. These unique walkthroughs let buyers and investors virtually explore every corner of your project, experiencing its flow, scale, and design with unparalleled realism. More than visuals, our 360° videos create an emotional connection, helping viewers envision life within the space. Stand out in the market and accelerate sales with this unparalleled game-changing tool for architectural visualization.
                                    </p>
                                </div>
                                <button onClick={toggleExpand} style={{ marginTop: "10px" }} className="see-more-btn">
                                    {isExpanded ? "See Less" : "See More"}
                                </button>
                            </Col>
                        </Row>
                        <Row style={{ alignItems: 'center', overflow: 'hidden', marginTop: "40px" }}>
                            <Col md={6} data-aos="fade-right">
                                <div className={`transform-content ${isExpanded ? "expanded" : "collapsed1"}`}>
                                    <h3 style={{ margin: "0px 0 10px 0 " }}>Cinematic Videos: Showcase It Before It's Built </h3>
                                    <p style={{ margin: "0 0 20px 0 ", textAlign: "justify" }}>
                                        Stand out in the fast-paced real estate market with RealtyVerse’s immersive cinematic videos. Go beyond static images to highlight your project's architecture, vibrant community, and surrounding environment. Showcase the lifestyle your development offers, creating a deeper connection with buyers. Our cinematic videos don’t just present features—they craft an experience that excites, engages, and accelerates sales.
                                    </p>
                                </div>
                                <button onClick={toggleExpand} style={{ marginTop: "10px" }} className="see-more-btn">
                                    {isExpanded ? "See Less" : "See More"}
                                </button>
                            </Col>
                            <Col md={6} data-aos="fade-left" className="order-2 mt-sm-0 mt-3">
                                <video ref={video360Ref} preload="auto" autoPlay playsInline loop style={{ borderRadius: "20px" }} muted>
                                    <source src="/assets/images/cuverse/exterior.mp4" type="video/mp4" />
                                </video>
                            </Col>
                        </Row>
                        <div className="inventory-section">
                            <Row className="mt-5 mb-5" style={{ alignItems: 'center', overflow: 'hidden' }}>
                                <Col md={6} className="order-md-1 order-2  mt-md-0 mt-3" data-aos="fade-right">
                                    <video ref={video360Ref} preload="auto" autoPlay playsInline loop style={{ borderRadius: "20px" }} muted>
                                        <source src="/assets/images/cuverse/Live_Inventory.mp4" type="video/mp4" />
                                    </video>
                                </Col>
                                <Col md={6} className="order-md-2 order-1" data-aos="fade-left">
                                    <div className={`transform-content ${isExpanded ? "expanded" : "collapsed"}`}>
                                        <h3 style={{ margin: "0 0 10px 0 " }}>Live Inventory: See Only Available Homes</h3>
                                        <p style={{ margin: "0 0 20px 0 ", textAlign: "justify" }}>
                                            Say goodbye to wasted tours and outdated listings. RealtyVerse’s "Live Inventory Update" technology ensures you see only available properties in real time. The moment a property is under contract, it’s removed from the listings, saving you time and frustration. Stay in control and focus on homes that match your needs with this game-changing feature.
                                        </p>
                                    </div>
                                    <button onClick={toggleExpand} style={{ marginTop: "10px" }} className="see-more-btn">
                                        {isExpanded ? "See Less" : "See More"}
                                    </button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default OurOffering;
