import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const RenderTabs = () => {
    const [openLightbox, setOpenLightbox] = useState<boolean>(false);
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
    const [selectedImages, setSelectedImages] = useState<any[]>([]);

    // Generate interior and exterior images with thumbnails and originals
    const interiorImages = Array.from({ length: 40 }, (_, i) => ({
        original: `/assets/images/renders/render_images/interior/${i + 1}.jpg`, // Full-size image
        thumbnail: `/assets/images/renders/render_images/interior/thumbnails/${i + 1}.jpg`, // Thumbnail image
    }));

    const exteriorImages = Array.from({ length: 11 }, (_, i) => ({
        original: `/assets/images/renders/render_images/exterior/${i + 1}.jpg`, // Full-size image
        thumbnail: `/assets/images/renders/render_images/exterior/thumbnails/${i + 1}.jpg`, // Thumbnail image
    }));

    // Combine both images into one array
    const combinedImages = [...interiorImages, ...exteriorImages];

    useEffect(() => {
        // Add a condition to check if selectedImages need to be updated
        if (JSON.stringify(selectedImages) !== JSON.stringify(combinedImages)) {
            setSelectedImages(combinedImages);
        }
    }, [combinedImages, selectedImages]);

    return (
        <div className="render-page">
            <div className="pre-design-content render-tabs-items">
                <div className="mixed-renders">
                    <Row>
                        {selectedImages.map((img, index) => (
                            <Col
                                md={3}
                                sm={4}
                                key={index}
                                onClick={() => {
                                    setCurrentImageIndex(index); // Set the clicked image index
                                    setOpenLightbox(true); // Open the modal
                                }}
                            >
                                <div style={{ cursor: "pointer", marginBottom: "20px", position: "relative" }}>
                                    {/* Display thumbnails in the grid */}
                                    <img
                                        className="img-fluid"
                                        src={img.thumbnail}
                                        alt={`Image ${index + 1}`}
                                        loading="lazy"
                                    />
                                    <div className="preview-images-tabs">
                                        <small>Preview</small>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>

            {/* Modal (Lightbox) to display original full-size images */}
            <Lightbox
                open={openLightbox}
                close={() => setOpenLightbox(false)} // Close modal
                slides={selectedImages.map((img) => ({ src: img.original }))} // Use original images in Lightbox
                index={currentImageIndex} // Start at the clicked image
            />
        </div>
    );
};

export default RenderTabs;
