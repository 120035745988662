import React, { useEffect, useState } from "react";
import { useApp } from "../hooks/AppHooks";
import BannerCarousel from "../components/bannerCaraousel/component";
import ContactUsComponent from "../components/contactUs/component";
import { Col, Container, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AOS from "aos";
import "aos/dist/aos.css";
import { INVENTORY_2D_API } from "../auth/constant";

const ContactPage: React.FC = () => {
    const { homeEnglishData } = useApp();
    const bannerImage = homeEnglishData?.contactus?.bannerSection;
    const contactData = homeEnglishData?.contactus;

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [mobNumber, setMobNumber] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [closeBtn, setCloseBtn] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState<string>('US');

    const [show, setShow] = useState(false);

    const onclickHide = () => {
        setShow(false);
        if (!show) {
            setCloseBtn(true);
        }
    }

    const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        const formData = {
            firstName,
            lastName,
            email,
            mobNumber,
            message,
            code
        };

        try {
            const response = await axios.post(INVENTORY_2D_API + 'user/postQuery', formData);
            console.log('Form submitted successfully!', response.data);
            setFirstName('');
            setLastName('');
            setEmail('');
            setMobNumber('');
            setMessage('');
            setCode('');
            setShow(true);
            setLoading(false);
            if (closeBtn) {
                window.location.reload()
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setLoading(false);
        }
    }

    const countryCodes: { [key: string]: string } = { US: '+1', IN: '+91', GB: '+44', CA: '+1', AU: '+61', FR: '+33', DE: '+49', IT: '+39', ES: '+34', MX: '+52', BR: '+55', JP: '+81', CN: '+86', KR: '+82', ZA: '+27', RU: '+7', SE: '+46', NO: '+47', DK: '+45', FI: '+358', IE: '+353', NL: '+31', BE: '+32', LU: '+352', CH: '+41', AT: '+43', PL: '+48', GR: '+30', TR: '+90', EG: '+20', AR: '+54', CL: '+56', CO: '+57', PE: '+51', VE: '+58', PH: '+63', SG: '+65', TH: '+66', MY: '+60', VN: '+84', ID: '+62', PK: '+92', BD: '+880', LK: '+94', KW: '+965', QA: '+974', AE: '+971', SA: '+966', OM: '+968', JO: '+962', BH: '+973', LY: '+218', DZ: '+213', MA: '+212', TN: '+216', KE: '+254', NG: '+234', UG: '+256', TZ: '+255', GH: '+233', CI: '+225', RW: '+250', CM: '+237', MG: '+261', MW: '+265', ZM: '+260', NP: '+977', IS: '+354', MT: '+356', LT: '+370', LV: '+371', EE: '+372', BY: '+375', MD: '+373', UA: '+380', BG: '+359', RO: '+40', HR: '+385', SI: '+386', SK: '+421', CZ: '+420', HU: '+36', RS: '+381', MK: '+389', AL: '+355', AM: '+374', GE: '+995', AZ: '+994', IQ: '+964', SY: '+963', LB: '+961', PT: '+351', CY: '+357', PA: '+507', GT: '+502', HN: '+504', CR: '+506', NI: '+505', SV: '+503', DO: '+1-809', HA: '+963' };



    useEffect(() => {
        const fetchCountryCode = async () => {
            try {
                const response = await fetch('https://ipinfo.io/json?token=891b53fd44bf1c');
                console.log(response , "responseresponse")
                const data = await response.json();
                const country = data.country;

                setSelectedCountry(country);
                setCode(countryCodes[country] || '+1'); // Default to +1 if country not found
            } catch (error) {
                console.error('Error fetching country data:', error);
            }
        };

        fetchCountryCode();
    }, []);

    const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newCountry = e.target.value;
        setSelectedCountry(newCountry);
        setCode(countryCodes[newCountry]); // Update country code based on selected country
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <>
            <div className="conatact-page">
                <Row style={{ padding: "0" }}>
                    <Col md={6} style={{ padding: "0" }}>
                        <BannerCarousel bannerImage={bannerImage} />
                    </Col>
                    <Col md={6} style={{ padding: "0", background: "#1E2430" }}>
                        <div className="contact-form-parent" style={{ padding: "0", background: "#1E2430", height: "100%" }}>
                            <Form onSubmit={onSubmitHandler}
                                className="contact-form"
                            >
                                <h4 data-aos={window.innerWidth < 992 ? "" : "fade-down"} style={{ color: "#f2f5f7", overflow: 'hidden' }}>Contact Us</h4>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Group className="mb-3" controlId="firstName">
                                            {/* <Form.Label>First Name</Form.Label> */}
                                            <Form.Control
                                                data-aos={window.innerWidth < 992 ? "" : "fade-right"}
                                                type="text"
                                                placeholder="First name"
                                                value={firstName}
                                                required
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-3" controlId="lastName">
                                            {/* <Form.Label>Last Name</Form.Label> */}
                                            <Form.Control
                                                data-aos={window.innerWidth < 992 ? "" : "fade-left"}
                                                type="text"
                                                placeholder="Last Name"
                                                value={lastName}
                                                required
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <Row>
                                            <Col xs={4} className="pe-0">
                                                <Form.Group className="mb-3" controlId="code">
                                                    <Form.Select aria-label="Default select example"
                                                        data-aos={window.innerWidth < 992 ? "" : "zoom-in"}
                                                        onChange={handleCountryChange}
                                                        value={selectedCountry}
                                                        required
                                                    >
                                                        {Object.keys(countryCodes).map((country) => (
                                                            <option key={country} value={country}>
                                                                {country} ({countryCodes[country]})
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={8}>
                                                <Form.Group className="mb-3" controlId="mobNumber">
                                                    {/* <Form.Label>Mobile Number</Form.Label> */}
                                                    <Form.Control
                                                        data-aos={window.innerWidth < 992 ? "" : "zoom-in"}
                                                        type="text"
                                                        placeholder="Mobile Number"
                                                        value={mobNumber}
                                                        required
                                                        onChange={(e) => setMobNumber(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-3" controlId="email">
                                            {/* <Form.Label>Email address</Form.Label> */}
                                            <Form.Control
                                                data-aos={window.innerWidth < 992 ? "" : "zoom-in"}
                                                type="email"
                                                placeholder="email"
                                                value={email}
                                                required
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    {/* <Form.Label>Message</Form.Label> */}
                                    <Form.Control as="textarea" rows={3}
                                        data-aos={window.innerWidth < 992 ? "" : "fade-up"}
                                        data-aos-anchor-placement="top-bottom"
                                        placeholder="message"
                                        required
                                        value={message}
                                        onChange={(e) => { setMessage(e.target.value) }}
                                    />
                                </Form.Group>
                                <Row style={{marginLeft: "0" }} className="submit-btn-contact">
                                    {
                                        !loading ?
                                            <Button variant="secondary" type="submit"
                                                data-aos={window.innerWidth < 992 ? "" : "flip-left"}
                                                data-aos-easing="ease-out-cubic"
                                                data-aos-duration="2000"
                                                style={{ backgroundColor: "#08428D", borderColor: "transparent" }}>
                                                Submit Now
                                            </Button>
                                            :
                                            <Button variant="secondary" type="submit"
                                                data-aos="flip-left"
                                                data-aos-easing="ease-out-cubic"
                                                data-aos-duration="2000"
                                                style={{ backgroundColor: "#08428D", borderColor: "transparent" }}>
                                                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span> Submit Now
                                            </Button>

                                    }

                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
                <Container>
                    <ContactUsComponent contactData={contactData} />
                </Container>
                <Modal show={show} onHide={onclickHide}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body style={{ color: "#08428D" }}>Your Request Submitted Successfully! Thankyou For Contact.</Modal.Body>
                </Modal>
            </div>
        </>
    )
}
export default ContactPage;