import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/home';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Custom.scss';
import './App.css';
import HeaderC from './layout/header/component';
import FooterC from './layout/footer/component';
import ProductPage from './pages/ProductPage';
import ProjectPage from './pages/Project';
import DeveloperPage from './pages/Developers';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import CuversePage from './pages/ourOfferingPage/CuversePage';
import ArchitectureVisulization from './pages/ourOfferingPage/ArchitectureVisulization';
import BimPage from './pages/ourOfferingPage/BimPage';
import GreenBuilding from './pages/ourOfferingPage/GreenBuilding';
import PreDesign from './pages/ourOfferingPage/PreDesign';
import FacilitiesManagement from './pages/ourOfferingPage/FacilitiesManagement';
import FaqPage from './pages/FaqPage';
import BlogsPage from './pages/BlogsPage';
import ServicePage from './pages/Service';
import RenderPage from './pages/RendersPage';
import RenderPage360 from './components/service/Render360';
import BimSection from './components/service/BimSection';
import RenderPageCompo from './components/service/Render';
import CinematicVideos from './components/service/Cinematicvideos';
import GreenBuildingComp from './components/service/GreenBuilding';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// import ServicePage from './pages/Service';

const App: React.FC = () => {
  const [headerShow, setHeaderShow] = useState(false);
  const prevScrollPos = useRef(0);

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Quick Contact
    </Tooltip>
  );

  useEffect(() => {
    const toggleVisibility = () => {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos > 400 && currentScrollPos > prevScrollPos.current) {

        setHeaderShow(true); // Automatically hide header when scrolling down
      } else {
        setHeaderShow(false);
      }
      // prevScrollPos.current = currentScrollPos;
    };
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <BrowserRouter>
      {
        headerShow ?
          <>
            <div className='quick-contact'>
              <OverlayTrigger
                placement="left"
                overlay={renderTooltip}
              >
                <a target='_blank' href="https://api.whatsapp.com/send?phone=918962564800"><h6>
                  <img src="/inquiry.png" />
                </h6></a>
              </OverlayTrigger>

            </div>
            <div className='social-btns' style={{ display: 'none' }}>
              <div className='social-icons'>
                <a className="btn facebook" style={{ display: 'none' }} href="#"><i className="fa fa-facebook fa-brands"></i></a>
                <a className="btn linkedin" target='_blank' href="https://www.linkedin.com/in/cuverse-pvt-ltd-6b005631a/"><i className="fa fa-linkedin fa-brands"></i></a>
                <a className="btn youtube" target='_blank' href="https://www.youtube.com/channel/UCxxGVvZcXplo_-ZEMVI6iGA "><i className="fa fa-youtube fa-brands"></i></a>
                <a href="https://api.whatsapp.com/send?phone=918962564800" target='_blank' className="btn whatsapp"><i className="fa fa-brands fa-whatsapp"></i></a>
                <a className="btn instagram" target='_blank' href="https://www.instagram.com/cuverse_01/?hl=en" ><i className="fa fa-brands fa-instagram"></i></a>
              </div>
            </div>
            <div className='quick-contact' style={{ display: 'none' }}>
              <a target='_blank' href="https://api.whatsapp.com/send?phone=918962564800"><h6>Quick Contact</h6></a>
            </div>
          </>
          :
          ""
      }

      <HeaderC />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/rvision" element={<CuversePage />} />
        <Route path="/architecture-visualization" element={<ArchitectureVisulization />} />
        <Route path="/facilities-management" element={<FacilitiesManagement />} />
        <Route path="/pre-design" element={<PreDesign />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/project" element={<ProjectPage />} />
        <Route path="/developers" element={<DeveloperPage />} />
        <Route path="/aboutus" element={<AboutPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/portfolio" element={<RenderPage />} />
        <Route path="/bim" element={<BimPage />} />
        <Route path="/green-building" element={<GreenBuilding />} />
        <Route path="/services" element={<ServicePage />} />
        {/* Add other routes as needed */}
      </Routes>
      <FooterC />
    </BrowserRouter>
  );
};

export default App;
