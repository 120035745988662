import React from "react";
import { Col, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import "./style.scss";
import ReactPlayer from "react-player";

const BimSection = () => {
    const carouselItems = [
        {
            heading: "BIM",
            subheading: "Our Additional Value proposition​",
            content: `Improved design communication and collaboration reduced errors and inconsistencies, increased efficiency and cost savings throughout the project lifecycle, streamlined construction planning and sequencing, improved clash detection and avoidance, material quantification, and procurement optimization. Ability to create immersive virtual reality experiences (refer to previous content). With the details we have done the visualization, we can generate BIM models for the Architectural and MEP to provide all the required structural and construction details for your contractors and subcontractors. We propose our open BIM support to ensure complete data migration for both 2D and 3D drawings`,
            image1: "/assets/images/service/building.png",
            image2: "/assets/images/service/pre_des.jpg",
            extraContent: {
                subheading: "LOD 100 : Pre Design",
                points: [
                    "Basic Geometric Representation",
                    "Conceptual Design Phase",
                    "Minimal Details",
                ],
                description:
                    "At LOD 100 level, we define a conceptual model where parameters such as area, height, volume, location, and orientation are determined",
            },
        },
        {
            heading: "BIM",
            subheading: "LOD 200 : Schematic Design",
            content: `Forget constantly adjusting the thermostat! We analyze airflow patterns to understand how each apartment handles hot and cold air.  Here's how this translates to comfort`,
            points: [
                "Specific Information",
                "Refined Geometry",
                "Basic Connectivity",
                "Intermediate Design Phase",
            ],
            description:
                "At LOD 200 level, elements are modeled with approximate quantities, size, shape, location, and orientation.",
            image1: "/assets/images/service/schematic.jpg",
            image2: "/assets/images/service/des_dev.jpg",
            extraContent: {
                subheading: "LOD 300 : Design Development",
                points: [
                    "Floors and Ceiling Plans",
                    "Elevations",
                    "Sections",
                    "3D Views",
                    "Area Calculations",
                    "Structure Details and Analysis",
                ],
                description:
                    "This LOD 300 level involves accurate modeling and detailed shop drawings, where elements are defined with specific assemblies, precise quantity, size, shape, location, and orientation.",
            },
        },
        {
            heading: "BIM",
            subheading: "LOD 400 : Construction Stage",
            points: [
                "Comprehensive Detailing",
                "Accurate Geometry",
                "Detailed Connectivity and Integration",
                "Mechanical, Electrical, and Plumbing Details.",
                "Schedules and Quantity Take-offs",
                "Constructions Documentation",
            ],
            description:
                "At LOD 400 stage, model elements are represented as specific assemblies, complete with fabrication, assembly, and detailed information, in addition to precise quantity, size, shape, location, and orientation.",
            image1: "/assets/images/service/construction.jpg",
            image2: "/assets/images/service/built_model.png",
            extraContent: {
                subheading: "LOD 500 : As-built Models",
                points: [
                    "As-Built Information",
                    "Full Asset Information",
                    "Maintenance and Operations Data",
                    "Construction Lifecycle Information",
                    "Schedules and Quantity Take-offs",
                ],
                description:
                    "At LOD 500 elements are modeled as constructed assemblies for operations and maintenance. They are accurate in terms of size, shape, location, quantity, and orientation, and non-geometric information can be embedded within model elements at LOD 500.",
            },
        },
        {
            heading: "BIM",
            subheading: "LOD 600 : Sustainability",
            points: [
                "Operational Data",
                "Water Efficiency",
                "Material Efficiency",
                "Energy Efficiency",
            ],
            description:
                "LOD 600 supports the building owners and operators to effectively manage their data throughout their lifecycle. It enables the water, material, and energy efficiency measure. These offer economic benefits through lower utility costs, enhanced occupant health and comfort, and increased property value. These sustainable practices contribute to a more resilient and resource-efficient built environment for current and future generations.",
            image1: "/assets/images/service/susta.png",
            image2: "/assets/images/service/lod_700.png",
            extraContent: {
                subheading: "LOD 700 : Facility Management",
                points: [
                    "Fusion data",
                    "AI Monitoring and Management",
                    "Asset Lifecycle Management",
                    "Remote Monitoring and Control",
                ],
                description:
                    "LOD 700 models leverage fusion data, AI monitoring, asset lifecycle management, and remote monitoring and control capabilities to create intelligent and adaptive building environments that are optimized for performance, efficiency, and sustainability throughout their lifecycle. These advanced capabilities enable building owners and operators to make data-driven decisions, reduce operational costs, and enhance the comfort and well-being of building occupants.",
            },
        },
        {
            video: "/assets/images/renders/bim_explain.mp4",
        },
    ];

    return (
        <div className="bim-section tabsHeading">
            <h6><span>BIM</span></h6>
            <div className="first-carousel bim-carousel bim-s">
                <div className="bim-content-para" style={{ margin: "20px 0 24px", textAlign: 'center' }}>
                    <h3>BUILDING INFORMATION MODEL​</h3>
                    <p>
                        Building information model provides a comprehensive and integrated
                        approach to building design and management, improving efficiency,
                        reducing errors, and enhancing communication throughout the project
                        lifecycle.
                    </p>
                </div>
                <Carousel data-bs-theme="dark">
                    {carouselItems.map((item, index) => (
                        <Carousel.Item interval={4000} key={index}>
                            {item.video ? (
                                <Row>
                                    <Col>
                                        <ReactPlayer
                                            width="100%"
                                            height="100%"
                                            url={item.video}
                                            playing
                                            loop
                                            muted
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <>
                                    <Row style={{ justifyContent: "end", alignItems: "center" }}>
                                        <Col md={6} className="service-content-new order-md-1 order-2">
                                            <div className="pre-design-content">
                                                {/* <strong
                                                    className="main-page-heading"
                                                    style={{ textAlign: "center" }}
                                                >
                                                    <span>{item.heading}</span>
                                                </strong> */}
                                                <h3>{item.subheading}</h3>
                                                <p>{item.content}</p>
                                                {item.points && (
                                                    <ul>
                                                        {item.points.map((point, idx) => (
                                                            <li key={idx}>{point}</li>
                                                        ))}
                                                    </ul>
                                                )}
                                                <p>{item.description}</p>
                                            </div>
                                        </Col>
                                        <Col md={7}>
                                            <img
                                                src={item.image1}
                                                alt={item.subheading}
                                                className="img-fluid"
                                            />
                                        </Col>
                                    </Row>
                                    {item.extraContent && (
                                        <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
                                            <Col md={7}>
                                                <img
                                                    src={item.image2}
                                                    alt={item.extraContent.subheading}
                                                    className="img-fluid"
                                                />
                                            </Col>
                                            <Col md={6} className="service-content-new1">
                                                <div className="pre-design-content">
                                                    <h3>{item.extraContent.subheading}</h3>
                                                    <ul>
                                                        {item.extraContent.points.map((point, idx) => (
                                                            <li key={idx}>{point}</li>
                                                        ))}
                                                    </ul>
                                                    <p>{item.extraContent.description}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                </>
                            )}
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default BimSection;
