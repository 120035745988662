import React, { useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import CinematicVideosTabs from "../components/renders/CinematicVideos";
import Render360Tabs from "../components/renders/Render360Tabs";
import RenderTabs from "../components/renders/RenderTabs";
import Videos360Tabs from "../components/renders/Videos360Tabs";
import BackBtn from "../back-btn.png"

const RenderPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    const currentTab = new URLSearchParams(location.search).get("tab") || "renders";

    const handleSelect = (key: any) => {
        navigate(`?tab=${key}`);
    };

    return (
        <div className="render-page-tabs">
            {/* <div className="render-banner">
                <img src="/assets/images/renders/render_images/interior/1.jpg" className="img-fluid" alt="Render Banner" />
            </div> */}
            <h1 style={{
                textAlign: "center",
                position: "relative",
                top: "15px",
                fontSize: "30px",
                color: "#1D458A",
                fontWeight:"700"

            }}>Gallery</h1>
            <Container className="detail-img">
                <div>
                    <button className="back-btn" onClick={() => { navigate('/services') }}> <img src={BackBtn} alt="back button" /></button>
                    <Tabs
                        activeKey={currentTab}
                        onSelect={handleSelect}
                        id="uncontrolled-tab-example"
                    >
                        <Tab eventKey="renders" title="Renders">
                            <RenderTabs />
                        </Tab>
                        <Tab eventKey="360renders" title="360 Renders">
                            <Render360Tabs />
                        </Tab>
                        <Tab eventKey="videos" title="360 Videos">
                            <Videos360Tabs />
                        </Tab>
                        <Tab eventKey="cinematic" title="Cinematic Videos">
                            <CinematicVideosTabs />
                        </Tab>
                    </Tabs>
                </div>
            </Container>
        </div>
    );
};

export default RenderPage;
