import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Slider from "react-slick";
import ReactPannellum from "react-pannellum";
import { Link } from "react-router-dom";

const RenderPage360 = () => {
    const [openModal, setOpenModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState<any>(0);
    const [modalType, setModalType] = useState<'interior' | 'exterior' | null>(null);
    const [selectedImages, setSelectedImages] = useState<any[]>([]);
    const [selectedImagesExterior, setSelectedImagesExterior] = useState<any[]>([]);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const interiorMedia = Array.from({ length: 13 }, (_, i) => ({
        src: `/assets/images/renders/render_360/interior/${i + 1}.jpg`,
        thumbnail: `/assets/images/renders/render_360/interior/0${i + 1}.jpg`,
    }));

    const exteriorMedia = Array.from({ length: 6 }, (_, i) => ({
        src: `/assets/images/renders/render_360/exterior/${i + 1}.jpg`,
        thumbnail: `/assets/images/renders/render_360/exterior/0${i + 1}.jpg`,
    }));

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 4000
    };

    const handleThumbnailClick = (index: number, type: 'interior' | 'exterior') => {
        setSelectedIndex(index);
        setModalType(type);
        setOpenModal(true);
        handleShow();
    };

    useEffect(() => {
        setSelectedImages(interiorMedia);
        setSelectedImagesExterior(exteriorMedia);
    }, []);

    return (
        <div className="render-page tabsHeading">
            <h6><span>360 Renders</span></h6>
            <div className="pre-design-content">
                {/* Interior Renders */}
                <div className="interior-renders">
                    <Row className="first-slide-service">
                        <Col md={6} className="service-content-new order-md-1 order-2" data-aos="fade-right">
                            <strong className="main-page-heading" style={{ textAlign: "center" }}>
                                <span>Interior 360 Renders</span>
                            </strong>
                            <div className="renders-content render-360">
                                <p><strong>Interior 360 Renders</strong> provide an immersive, interactive view of interior spaces, allowing users to explore designs from every angle. Ideal for real estate and interior design, these renders bring spaces to life with stunning realism.</p>
                                <ul>
                                    <li>Immersive 360-degree views of interior spaces.</li>
                                    <li>High-resolution, interactive visuals that engage clients.</li>
                                    <li>Perfect for real estate, interior design, and architectural projects.</li>
                                    <li>Showcases layouts, lighting, and decor from all perspectives.</li>
                                    <li>Easily integrated into websites for seamless navigation.</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={7}>
                            <Slider {...settings}>
                                {selectedImages.slice(0, 3).map((row: any, index: number) => (
                                    <div key={index} style={{ cursor: "pointer", marginBottom: "10px" }}>
                                        <div
                                            className="renderImagesBox"
                                            style={{
                                                cursor: "pointer",
                                                width: '100%',
                                                margin: 'auto',
                                                position: 'relative',
                                                marginTop: '2px'
                                            }}
                                            onClick={() => handleThumbnailClick(index, 'interior')}
                                        >
                                            <img className="img-fluid" src={row.thumbnail} alt={`Interior render ${index + 1}`} />
                                            <div className="preview-images bottom-menu">
                                                <Link to="/portfolio?tab=360renders">
                                                    <img src="/assets/images/see_all.png" alt="See all" />
                                                </Link>
                                                <img onClick={() => handleThumbnailClick(index, 'interior')} src="/assets/images/rotate.png" width={"50px"} alt="Rotate" />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </Col>
                    </Row>
                </div>

                {/* Exterior Renders */}
                <div className="exterior-renders">
                    <Row className="second-slide-service">
                        <Col md={7}>
                            <Slider {...settings}>
                                {selectedImagesExterior.slice(0, 3).map((row: any, index: number) => (
                                    <div key={index} style={{ cursor: "pointer", marginBottom: "10px" }}>
                                        <div
                                            className="renderImagesBox"
                                            style={{
                                                cursor: "pointer",
                                                width: '100%',
                                                margin: 'auto',
                                                position: 'relative'
                                            }}
                                            onClick={() => handleThumbnailClick(index, 'exterior')}
                                        >
                                            <img className="img-fluid less-space" src={row.thumbnail} alt={`Exterior render ${index + 1}`} />
                                            <div className="preview-images top-menu">
                                                <Link to="/portfolio?tab=360renders">
                                                    <img src="/assets/images/see_all.png" alt="See all" />
                                                </Link>
                                                <img onClick={() => handleThumbnailClick(index, 'exterior')} src="/assets/images/rotate.png" width={"50px"} alt="Rotate" />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </Col>
                        <Col md={6} className="service-content-new1" data-aos="fade-left">
                            <strong className="main-page-heading" style={{ textAlign: "center" }}>
                                <span>Exterior 360 Renders</span>
                            </strong>
                            <div className="renders-content">
                                <p><strong>Exterior 360 Renders</strong> offer an immersive, interactive view of building exteriors, allowing users to explore architectural designs from all angles. Perfect for real estate and architectural presentations, these renders enhance client engagement with stunning realism.</p>
                                <ul>
                                    <li>Interactive 360-degree views of exterior spaces.</li>
                                    <li>High-quality, immersive visuals that captivate clients.</li>
                                    <li>Ideal for real estate, architecture, and landscape design.</li>
                                    <li>Showcases architectural details and outdoor environments.</li>
                                    <li>Easily embedded into websites for seamless exploration.</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>

                {/* Modal for both interior and exterior */}
                {openModal && modalType && (
                    <Modal show={show} onHide={handleClose} className="video-modal render-modal-section">
                        <ReactPannellum
                            id="1"
                            width={'100%'}
                            height={'100%'}
                            sceneId="firstScene"
                            imageSource={modalType === 'interior' ? selectedImages[selectedIndex]?.src : selectedImagesExterior[selectedIndex]?.src}
                            config={{
                                autoLoad: true,
                                mouseZoom: false,
                                disableZoom: true,
                                hfov: 130,
                                autoRotate: -6
                            }}
                        />
                        <button onClick={handleClose} className="render-closeBtn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                            </svg>
                        </button>
                    </Modal>
                )}
            </div>
        </div>
    );
};

export default RenderPage360;
