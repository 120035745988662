import React, { useRef, useState } from "react";
import ReactPlayer from 'react-player';
import { Modal, Button } from 'react-bootstrap';

const Videos360Tabs = () => {
    const [mediaShow, setMediaShow] = useState<any>(true);
    const [selectedMedia, setSelectedMedia] = useState<any>(null);
    const [open, setOpen] = useState<any>(false);
    const playerRef = useRef<any>(null);
    const [moveHide, setMoveHide] = useState<any>(false)

    const interiorMedia = [

        {
            title: "seef",
            type: "video",
            src: "https://www.youtube.com/watch?v=5ZKGONwheuI=PLbzBcKDpIG9hj4YnWyAeo-iivTIbSEst7&index=3",
            thumbnail: "/assets/images/renders/videos_360/seef2.jpg",
        },
        {
            title: "seef1",
            type: "video",
            src: "https://www.youtube.com/watch?v=3YJ9oyDiOYA=PLbzBcKDpIG9hj4YnWyAeo-iivTIbSEst7&index=4",
            thumbnail: "/assets/images/renders/videos_360/seef1.jpg",
        },
        {
            title: "al_ramz",
            type: "video",
            src: "https://www.youtube.com/watch?v=IhhYZawvOkA=PLbzBcKDpIG9hj4YnWyAeo-iivTIbSEst7&index=5",
            thumbnail: "/assets/images/renders/videos_360/al_ramz1.jpg",
        },
        {
            title: "Green Plaza",
            type: "video",
            src: "https://youtu.be/tzEEUlfrp7Y&index=1",
            thumbnail: "/assets/images/renders/videos_360/seef.jpg",
        },
        {
            title: "Green City",
            type: "video",
            src: "https://youtu.be/pLupt-XwyRA&index=2",
            thumbnail: "/assets/images/renders/videos_360/Green_plaza.jpg",
        },
        {
            title: "Green City",
            type: "video",
            src: "https://youtu.be/HGE2hBdjp5o",
            thumbnail: "/assets/images/renders/videos_360/seef4.jpg",
        },
        {
            title: "Green City",
            type: "video",
            src: "https://youtu.be/w8a_athThDU",
            thumbnail: "/assets/images/renders/videos_360/seef5.jpg",
        },
        {
            title: "Green City",
            type: "video",
            src: "https://youtu.be/q1SD8AQNLqU",
            thumbnail: "/assets/images/renders/videos_360/seef6.jpg",
        },
        // Add more media objects as needed
    ];

    const handleCloseModal = () => {
        setOpen(false);
        setSelectedMedia(null);
        setMediaShow(true);
    };

    const handleThumbnailClick = (media: any) => {
        setSelectedMedia(media);
        setOpen(true);
        setMoveHide(true);
    };

    return (
        <>
            <div className="renderImages">
                <div className="renderImages360 render-tabs-items">
                    {mediaShow && (
                        <div className="row">
                            {interiorMedia.map((media, index) => (
                                <div key={index} className="col-md-3 col-sm-4" style={{ cursor: "pointer", marginBottom: "20px" }}>
                                    <div style={{ cursor: "pointer", width: '100%', margin: 'auto', position: 'relative' }} className="ImagesBox360" onClick={() => handleThumbnailClick(media)}>
                                        <img
                                            className="img-fluid"
                                            src={media.thumbnail}
                                            alt="Thumbnail"
                                        />
                                        <div className="preview-images-tabs"><small>
                                            <svg fill="#ffffff" height="40px" width="40px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 60 60" >
                                                <g>
                                                    <path d="M45.563,29.174l-22-15c-0.307-0.208-0.703-0.231-1.031-0.058C22.205,14.289,22,14.629,22,15v30
                                                    c0,0.371,0.205,0.711,0.533,0.884C22.679,45.962,22.84,46,23,46c0.197,0,0.394-0.059,0.563-0.174l22-15
                                                    C45.836,30.64,46,30.331,46,30S45.836,29.36,45.563,29.174z M24,43.107V16.893L43.225,30L24,43.107z"/>
                                                    <path d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30
                                                    S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"/>
                                                </g>
                                            </svg>
                                        </small></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <Modal show={open} onHide={handleCloseModal} className="render-modal-section">
                <Modal.Body>
                    {selectedMedia && selectedMedia.type === 'video' && (
                        <ReactPlayer
                            url={selectedMedia.src}
                            className='react-player'
                            playing={true}
                            autoPlay={true}
                            width='100%'
                            height='100%'
                            controls={true}
                            loop={true}
                            ref={playerRef}
                            playsinline={true}
                            muted
                        />
                    )}
                    <button onClick={handleCloseModal} className="render-closeBtn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                        </svg>
                    </button>
                    {/* {
                        moveHide ?
                            <div className="move-direction" onClick={() => { setMoveHide(false) }}>
                                <img src="/assets/images/hand.gif"/>
                            </div>
                            :
                            ""
                    } */}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Videos360Tabs;
