import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import ReactPannellum from "react-pannellum";
// import Placeholder from 'react-bootstrap/Placeholder';

// Define a type for the media items
interface MediaItem {
    src: string;
    thumbnail: string;
}

const Render360Tabs: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedMedia, setSelectedMedia] = useState<MediaItem | null>(null);
    const [loadedImages, setLoadedImages] = useState<{ [key: string]: boolean }>({});

    const interiorMedia: MediaItem[] = [
        {
            src: "/assets/images/renders/render_360/interior/1.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/01.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/2.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/02.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/3.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/03.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/4.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/04.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/5.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/05.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/6.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/06.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/7.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/07.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/8.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/08.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/9.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/09.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/10.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/010.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/11.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/011.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/12.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/012.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/13.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/013.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/13.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/013.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/14.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/014.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/15.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/015.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/16.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/016.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/17.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/017.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/18.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/018.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/19.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/019.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/20.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/020.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/21.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/021.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/22.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/022.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/23.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/023.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/24.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/024.jpg",
        },
        {
            src: "/assets/images/renders/render_360/interior/25.jpg",
            thumbnail: "/assets/images/renders/render_360/interior/025.jpg",
        },
        {
            src: "/assets/images/renders/render_360/exterior/1.jpg",
            thumbnail: "/assets/images/renders/render_360/exterior/01.jpg",
        },
        {
            src: "/assets/images/renders/render_360/exterior/2.jpg",
            thumbnail: "/assets/images/renders/render_360/exterior/02.jpg",
        },
        {
            src: "/assets/images/renders/render_360/exterior/3.jpg",
            thumbnail: "/assets/images/renders/render_360/exterior/03.jpg",
        },
        {
            src: "/assets/images/renders/render_360/exterior/4.jpg",
            thumbnail: "/assets/images/renders/render_360/exterior/04.jpg",
        },
        {
            src: "/assets/images/renders/render_360/exterior/5.jpg",
            thumbnail: "/assets/images/renders/render_360/exterior/05.jpg",
        },
        {
            src: "/assets/images/renders/render_360/exterior/6.jpg",
            thumbnail: "/assets/images/renders/render_360/exterior/06.jpg",
        },
        {
            src: "/assets/images/renders/render_360/exterior/7.jpg",
            thumbnail: "/assets/images/renders/render_360/exterior/07.jpg",
        },
        {
            src: "/assets/images/renders/render_360/exterior/8.jpg",
            thumbnail: "/assets/images/renders/render_360/exterior/08.jpg",
        },
        {
            src: "/assets/images/renders/render_360/exterior/9.jpg",
            thumbnail: "/assets/images/renders/render_360/exterior/09.jpg",
        },
        {
            src: "/assets/images/renders/render_360/exterior/10.jpg",
            thumbnail: "/assets/images/renders/render_360/exterior/010.jpg",
        }
        // Add more media objects as needed
    ];

    const handleCloseModal = () => {
        setOpen(false);
        setSelectedMedia(null);
    };

    const handleThumbnailClick = (media: MediaItem) => {
        setSelectedMedia(media);
        setOpen(true);
    };

    const handleImageLoad = (src: string) => {
        setLoadedImages((prev) => ({ ...prev, [src]: true }));
    };

    return (
        <div className="render-page">
            <Row>
                <div className="pre-design-content render-tabs-items">
                    <div className="renders">
                        <Row>
                            {interiorMedia.map((media, index) => (
                                <Col md={3} sm={4} key={index}>
                                    <div style={{ cursor: "pointer", marginBottom: "10px" }}>
                                        <div
                                            className="renderImagesBox"
                                            style={{
                                                cursor: "pointer",
                                                width: "100%",
                                                margin: "auto",
                                                position: "relative",
                                            }}
                                            onClick={() => handleThumbnailClick(media)}
                                        >
                                            {/* {!loadedImages[media.thumbnail] && (
                                               <Placeholder.Button xs={4} aria-hidden="true" />
                                            )} */}
                                            <img
                                                className="img-fluid"
                                                src={media.thumbnail}
                                                alt={`Render ${index + 1}`}
                                                aria-label={`Open 360 render ${index + 1}`}
                                                onLoad={() => handleImageLoad(media.thumbnail)}
                                                style={{
                                                    display: loadedImages[media.thumbnail] ? "block" : "none",
                                                }}
                                            />
                                            <div className="preview-images-tabs">
                                                <small>
                                                    <svg fill="#ffffff" height="40px" width="40px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 480 480">
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path d="M391.502,210.725c-5.311-1.52-10.846,1.555-12.364,6.865c-1.519,5.31,1.555,10.846,6.864,12.364
                                                                    C431.646,243.008,460,261.942,460,279.367c0,12.752-15.51,26.749-42.552,38.402c-29.752,12.82-71.958,22.2-118.891,26.425
                                                                    l-40.963-0.555c-0.047,0-0.093-0.001-0.139-0.001c-5.46,0-9.922,4.389-9.996,9.865c-0.075,5.522,4.342,10.06,9.863,10.134
                                                                    l41.479,0.562c0.046,0,0.091,0.001,0.136,0.001c0.297,0,0.593-0.013,0.888-0.039c49.196-4.386,93.779-14.339,125.538-28.024
                                                                    C470.521,316.676,480,294.524,480,279.367C480,251.424,448.57,227.046,391.502,210.725z"/>
                                                                    <path d="M96.879,199.333c-5.522,0-10,4.477-10,10c0,5.523,4.478,10,10,10H138v41.333H96.879c-5.522,0-10,4.477-10,10
                                                                    s4.478,10,10,10H148c5.523,0,10-4.477,10-10V148c0-5.523-4.477-10-10-10H96.879c-5.522,0-10,4.477-10,10s4.478,10,10,10H138
                                                                    v41.333H96.879z"/>
                                                                    <path d="M188.879,280.667h61.334c5.522,0,10-4.477,10-10v-61.333c0-5.523-4.477-10-10-10h-51.334V158H240c5.523,0,10-4.477,10-10
                                                                    s-4.477-10-10-10h-51.121c-5.523,0-10,4.477-10,10v122.667C178.879,276.19,183.356,280.667,188.879,280.667z M198.879,219.333
                                                                    h41.334v41.333h-41.334V219.333z"/>
                                                                    <path d="M291.121,280.667h61.334c5.522,0,10-4.477,10-10V148c0-5.523-4.478-10-10-10h-61.334c-5.522,0-10,4.477-10,10v122.667
				                                                    C281.121,276.19,285.599,280.667,291.121,280.667z M301.121,158h41.334v102.667h-41.334V158z"/>
                                                                    <path d="M182.857,305.537c-3.567-4.216-9.877-4.743-14.093-1.176c-4.217,3.567-4.743,9.876-1.177,14.093l22.366,26.44
                                                                    c-47.196-3.599-89.941-12.249-121.37-24.65C37.708,308.06,20,293.162,20,279.367c0-16.018,23.736-33.28,63.493-46.176
                                                                    c5.254-1.704,8.131-7.344,6.427-12.598c-1.703-5.253-7.345-8.13-12.597-6.427c-23.129,7.502-41.47,16.427-54.515,26.526
                                                                    C7.674,252.412,0,265.423,0,279.367c0,23.104,21.178,43.671,61.242,59.48c32.564,12.849,76.227,21.869,124.226,25.758
                                                                    l-19.944,22.104c-3.7,4.1-3.376,10.424,0.725,14.123c1.912,1.726,4.308,2.576,6.696,2.576c2.731,0,5.453-1.113,7.427-3.301
                                                                    l36.387-40.325c1.658-1.837,2.576-4.224,2.576-6.699v-0.764c0-2.365-0.838-4.653-2.365-6.458L182.857,305.537z"/>
                                                                    <path d="M381.414,137.486h40.879c5.522,0,10-4.477,10-10V86.592c0-5.523-4.478-10-10-10h-40.879c-5.522,0-10,4.477-10,10v40.894
				                                                    C371.414,133.009,375.892,137.486,381.414,137.486z M391.414,96.592h20.879v20.894h-20.879V96.592z"/>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>

                    {open && selectedMedia && (
                        <Modal
                            show={open}
                            onHide={handleCloseModal}
                            className="render-modal-section video-modal"
                        >
                            <ReactPannellum
                                id="panorama-viewer"
                                width="100%"
                                height="100%"
                                sceneId={`scene-${selectedMedia.src}`}
                                imageSource={selectedMedia.src}
                                config={{
                                    autoLoad: true,
                                    mouseZoom: false,
                                    disableZoom: true,
                                    hfov: 130,
                                    autoRotate: -6,
                                }}
                            />
                            <button onClick={handleCloseModal} className="render-closeBtn">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-x-circle-fill"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.354 4.646a.5.5 0 0 0-.708.708L7.293 7.5 5.646 9.146a.5.5 0 1 0 .708.708L8 8.207l1.646 1.647a.5.5 0 0 0 .708-.708L8.707 7.5l1.647-1.646a.5.5 0 0 0-.708-.708L8 6.793 6.354 4.646z" />
                                </svg>
                            </button>
                        </Modal>
                    )}
                </div>
            </Row>
        </div>
    );
};

export default Render360Tabs;
