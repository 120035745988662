import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import Slider from "react-slick";
import "./About.scss"

interface AboutDataProps {
    aboutData: any;
}
const AboutUsComponent: React.FC<AboutDataProps> = ({ aboutData }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        mute: false,
        muted: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    mute: false,
                    muted: false,
                }
            }

        ]
    };

    return (
        <>
            <div className="about-content">
                <h3 className="main-page-heading" style={{ textAlign: "center" }}><span>{aboutData?.heading}</span></h3>
                <Row>
                    <Col md={6}>
                        <p>{aboutData?.description}</p>
                    </Col>
                    <Col md={6}>
                        <Carousel pause={false}>
                            {
                                aboutData?.images?.map((item: any, index: number) => {
                                    return (
                                        <Carousel.Item key={index} interval={1000}>
                                            <img src={item} className="img-fluid" height={"300px"} />
                                        </Carousel.Item>
                                    )
                                })
                            }
                        </Carousel>
                    </Col>
                </Row>
                <div className="founder-message">
                    <h3>{aboutData?.message?.title}</h3>
                    <p>{aboutData?.message?.description}</p>
                </div>
                <div className="experience-box">
                    <h3>{aboutData?.experience?.title}</h3>
                    <Row style={{ alignItems: "center" }}>
                        <Col md={6}>
                            <div className="experience-image">
                                <img src={aboutData?.experience?.image} className="img-fluid" />
                                <p>{aboutData?.experience?.subtitle}</p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <p>{aboutData?.experience?.description}</p>
                        </Col>
                    </Row>
                </div>
                <div className="exhibition-section">
                    <p>{aboutData?.Exhibition?.title}</p>
                    <h3>{aboutData?.Exhibition?.heading}</h3>
                    <div className="exhibition-carousel">
                        <Carousel pause={false}>
                            {
                                aboutData?.Exhibition?.data.map((item: any, index: number) => {
                                    return (
                                        <Carousel.Item key={index} interval={2000}>
                                            <Row>
                                                <Col md={6}>
                                                    <Row>
                                                        <Col>
                                                            <img src={item?.image} className="img-fluid" />
                                                        </Col>
                                                        <Col>
                                                            <h4>{item?.title}</h4>
                                                            <p>{item?.description}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6}>
                                                    <Row>
                                                        <Col>
                                                            <img src={item?.image} className="img-fluid" />
                                                        </Col>
                                                        <Col>
                                                            <h4>{item?.title}</h4>
                                                            <p>{item?.description}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Carousel.Item>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                </div>
                <div className="cubedots-life">
                <Carousel pause={false}>
                        {
                            aboutData?.cubedotslife?.lifecontent.map((item: any, index: number) => {
                                return (
                                    <Carousel.Item key={index} interval={1000}>
                                        <Row>
                                            <Col md={6}>
                                                <img src={item?.image} className="img-fluid" />
                                            </Col>
                                            <Col md={6}>
                                                <h3>{aboutData?.cubedotslife?.heading}</h3>
                                                <p>{item?.description}</p>
                                            </Col>
                                        </Row>
                                    </Carousel.Item>
                                )
                            })
                        }
                    </Carousel>
                </div>
                <div className="our-team">
                    <h3>{aboutData?.ourteam?.heading}</h3>
                    <div className="slider-container">
                        <Slider {...settings}>
                            {
                                aboutData?.ourteam?.data?.map((item: any, index: number) => {
                                    return (
                                        <Row key={index} style={{ justifyContent: "center" }}>
                                            <Col md={11}>
                                                <Card>
                                                    <div className="our-team-items">
                                                        <img src={item?.image} className="img-fluid" />
                                                        <div className="our-team-content">
                                                            <h4>{item.title}</h4>
                                                            <small>{item.Design}</small>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AboutUsComponent;