import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import BimSection from "../components/service/BimSection";
import GreenBuilding from "../components/service/GreenBuilding";
import VideoComponent from "../components/home/videoSection/component";
import { useApp } from "../hooks/AppHooks";
import RenderPage from "../components/service/Render";
import CinematicVideosPage from "../components/service/Cinematicvideos";
import RenderPage360 from "../components/service/Render360";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Videos360 from "../components/service/Videos360";

const ServicePage: React.FC = () => {
    const { homeEnglishData } = useApp();
    const videourl = homeEnglishData?.services;
    // Create refs for each section
    const preDesignRef = useRef<HTMLElement | null>(null);
    const [key, setKey] = useState('home');

    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            Quick Contact
        </Tooltip>
    );

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    return (
        <>

            <div className="service-section home-page">
                {/* Attach refs to the components */}
                <section ref={preDesignRef} style={{ position: 'relative' }}>
                    {/* <PreDesign /> */}
                    <VideoComponent videourl={videourl} />
                    <div className='quick-contact'>
                        <OverlayTrigger
                            placement="left"
                            overlay={renderTooltip}
                        >
                            <a target='_blank' href="https://api.whatsapp.com/send?phone=918962564800"><h6>
                                <img src="/inquiry.png" />
                            </h6></a>
                        </OverlayTrigger>
                    </div>
                </section>
                <Container>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k: any) => setKey(k)}
                        className="service-tabs-menu"
                    >
                        <Tab eventKey="home" title="Renders">
                            <RenderPage />
                        </Tab>
                        <Tab eventKey="contact" title="360 Renders">
                            <RenderPage360 />
                        </Tab>
                        <Tab eventKey="contact3" title="360 Videos">
                            <Videos360 />
                        </Tab>
                        <Tab eventKey="profile" title="Cinematic Videos">
                            <CinematicVideosPage />
                        </Tab>
                        <Tab eventKey="contact1" title="BIM">
                            <BimSection />
                        </Tab>
                        <Tab eventKey="contact2" title="Green Building">
                            <GreenBuilding />
                        </Tab>
                    </Tabs>
                </Container>
            </div>
        </>
    );
};

export default ServicePage;
