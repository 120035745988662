import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import Modal from 'react-bootstrap/Modal';

const CinematicVideosTabs = () => {
    const [openModal, setOpenModal] = useState({ open: false, index: 0 });
    const [videoUrl, setVideoUrl] = useState(""); // Manage video URL separately

    const handleClose = () => setOpenModal({ open: false, index: 0 });

    const mediaData = {
        interior: [
            { src: "https://youtu.be/y9cP6q3_Ke4", thumbnail: "/assets/images/renders/cinematic/exterior/Seef.jpg" },
            { src: "https://youtu.be/MtoRwShZc5Y", thumbnail: "/assets/images/renders/cinematic/exterior/alramz_2.jpg" },
            { src: "https://youtu.be/_asIFC8uDOg", thumbnail: "/assets/images/renders/cinematic/exterior/alramz.jpg" },
            { src: "https://youtu.be/QM6KJBeG2qA", thumbnail: "/assets/images/renders/cinematic/interior/al_ramz.jpg"},
            { src: "https://youtu.be/C20DKpNCvSM", thumbnail: "/assets/images/renders/cinematic/interior/green_plaza.jpg" },
            { src: "https://youtu.be/IsXeMxlbXA8", thumbnail: "/assets/images/renders/cinematic/exterior/green_city.jpg" },
            { src: "https://youtu.be/ZEBaQ0oyOsc", thumbnail: "/assets/images/renders/cinematic/exterior/al_safa.jpg"},
            { src: "https://youtu.be/bznEeQ3YrMo", thumbnail: "/assets/images/renders/cinematic/interior/seef.jpg"}
        ]
    };

    useEffect(() => {
        if (openModal.open) {
            setVideoUrl(mediaData.interior[openModal.index].src); // Set the video URL based on the selected index
        } else {
            setVideoUrl(""); // Clear the URL when the modal is closed
        }
    }, [openModal]);

    const renderSection = (items: any) => (
        <Row>
            {items.map((item: any, index: any) => (
                <Col md={3} sm={4} key={index}>
                    <div
                        style={{ cursor: "pointer", marginBottom: "20px", position: 'relative' }}
                        onClick={() => setOpenModal({ open: true, index })}
                    >
                        <img src={item.thumbnail} className="img-fluid" alt="Video thumbnail" />
                        <div className="preview-images-tabs"><small>
                            <svg fill="#ffffff" height="40px" width="40px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 60 60" >
                                <g>
                                    <path d="M45.563,29.174l-22-15c-0.307-0.208-0.703-0.231-1.031-0.058C22.205,14.289,22,14.629,22,15v30
		c0,0.371,0.205,0.711,0.533,0.884C22.679,45.962,22.84,46,23,46c0.197,0,0.394-0.059,0.563-0.174l22-15
		C45.836,30.64,46,30.331,46,30S45.836,29.36,45.563,29.174z M24,43.107V16.893L43.225,30L24,43.107z"/>
                                    <path d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30
		S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"/>
                                </g>
                            </svg>
                        </small></div>
                    </div>
                </Col>
            ))}
        </Row>
    );

    return (
        <div className="render-page">
            <div className="pre-design-content render-tabs-items">
                {/* First section: Interior */}
                {renderSection(mediaData.interior)}
            </div>

            {openModal.open && (
                <Modal show={openModal.open} onHide={handleClose} className="render-modal-section" centered>
                    {videoUrl && ( // Render ReactPlayer only when the video URL is set
                        <ReactPlayer
                            url={videoUrl}
                            playing
                            controls
                            width="100%"
                            height="100%"
                            muted
                            autoPlay
                        />
                    )}
                    <button onClick={handleClose} className="render-closeBtn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                        </svg>
                    </button>
                </Modal>
            )}
        </div>
    );
};

export default CinematicVideosTabs;
